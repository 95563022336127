import React, { Suspense } from 'react';

// Router

import AppRouter from './AppRouter.js';

// CONTEXT

import { AuthProvider } from './Context/AuthContext.js';

//

import Loader from './Components/Loader/Loader.js';

import NotificationContainer from './Components/Notification/NotificationContainer.js';

//

function App() {

  return (
    <div className="app">
      <AuthProvider>
        <Suspense fallback={<Loader />}>
          <AppRouter />
        </Suspense>
      </AuthProvider>
      <NotificationContainer />
    </div>
  );

}

export default App;